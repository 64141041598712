<template>
  <div class="medical-prescription-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Cancelar atendimento</p>
      </header>

      <section class="modal-card-body">
        <p>
          Deseja mesmo cancelar este atendimento? Esta ação não pode ser
          desfeita.
        </p>

        <!-- <p>
          Caso contrário o valor será estornado ao cliente.
        </p> -->

        <!-- <p>
          <b-check v-model="generate_discount">
            Cancelamento com voucher
          </b-check>
        </p> -->
      </section>

      <footer class="modal-card-foot is-right d-flex justify-content-end">
        <b-button
          class="button-rounded remove-focus btn-success"
          @click="confirmCancel"
        >
          Sim, tenho certeza
        </b-button>

        <b-button
          class="button-rounded remove-focus btn-secondary ml-4"
          @click="$emit('close', false)"
        >
          Não
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    generate_discount: true,
  }),

  methods: {
    confirmCancel() {
      const { generate_discount } = this;
      this.$emit('close', { generate_discount });
    },
  },
};
</script>
