<template>
  <div class="medical-record-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Gravações do atendimento</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="content">
          <b-table :data="records" :loading="isLoading">
            <!-- <b-table-column v-slot="props" field="id" label="Gravação">
              Gravação {{ props.row.id }}
            </b-table-column> -->

            <b-table-column v-slot="props" field="date" label="Recuperado em">
              {{ props.row.created_at | date('DD/MM/YYYY [às] HH:mm') }}
            </b-table-column>

            <b-table-column v-slot="props" label="Opções" width="150">
              <b-tooltip label="Visualizar gravação" position="is-left">
                <b-button
                  @click="openMeetRecord(props.row.url)"
                  type="is-secondary"
                  size="is-small"
                  icon-right="eye"
                />
              </b-tooltip>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <b-icon icon="frown" size="is-large"></b-icon>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service';

export default {
  props: {
    scheduleId: Number,
  },
  data: () => ({
    isLoading: false,
    records: [],
  }),
  methods: {
    loadScheduleMeetRecords() {
      SchedulesService.getScheduleMeetRecords(this.scheduleId)
        .then(({ data }) => {
          this.records = data;
        })
        .catch(() =>
          this.$buefy.snackbar.open({
            message: 'Erro inesperado ao carregar registros das gravações',
            type: 'is-danger',
            position: 'is-bottom',
            duration: 5000,
            pauseOnHover: true,
          })
        )
        .finally(() => (this.isLoading = false));
    },
    openMeetRecord(url) {
      window.open(url, '_blank');
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadScheduleMeetRecords();
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-width: 300px;
  padding: 3%;
}
</style>
