<template>
  <div class="schedule-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

    <app-header
      goes-back
      icon="clipboard"
      :title="schedule.canceled_at ? 'Atendimento cancelado' : 'Atendimento'"
      :danger="!!schedule.canceled_at"
      :description="
        schedule.canceled_at
          ? null
          : 'O contato do cliente, bem como os links direcionadores, ficarão disponíveis a partir de 5 minutos antes do horário'
      "
    ></app-header>

    <section v-if="!isLoading">
      <div class="card">
        <div class="card-content">
          <div class="patient">
            <div class="patient__image">
              <b-img :src="schedule.patient.photo" fluid></b-img>
            </div>

            <div v-if="schedule.patient" class="patient__info">
              <h2>{{ schedule.patient.name }}</h2>

              <p v-if="schedule.patient.city">
                {{ schedule.patient.city.name }},
                {{ schedule.patient.city.state.name }}
              </p>

              <div class="actions d-flex">
                <div class="mr-2">
                  <b-button
                    v-if="showButtonConfirmed"
                    class="btn-success"
                    @click="onConfirmClick()"
                  >
                    Aceitar
                  </b-button>
                </div>

                <div class="mr-2" v-if="showActionsButtons">
                  <b-button
                    class="btn-success mr-1"
                    @click="openLink('whatsapp', schedule.patient.phone)"
                  >
                    Abrir no WhatsApp
                  </b-button>

                  <b-button
                    v-if="!showTelephone && showButtonPhone"
                    class="btn-info mr-1"
                    @click="openLink('phone')"
                  >
                    Ligar
                  </b-button>

                  <div
                    v-if="showTelephone"
                    @click="onCopyPhone"
                    class="d-inline-block col-auto px-0"
                    style="cursor: pointer"
                  >
                    <p id="phoneShow" v-b-tooltip.click="popoverClipboard">
                      {{
                        schedule.patient.phone
                          | mask(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
                      }}
                    </p>
                  </div>
                </div>

                <div>
                  <b-button
                    v-if="!hiddenBtnCancel"
                    @click="onCancelClick()"
                    class="btn-danger"
                    icon-left="ban"
                  >
                    {{
                      schedule.tatendimento_id == 1
                        ? 'Cancelar'
                        : 'Cancelar atendimento'
                    }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <schedule-status :schedule="schedule" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SchedulesService from '@/services/schedules.service.js';
import MedicalRecordModelService from '@/services/medical-records.service';

import Prescription from '@/modals/Prescription.vue';
import MeetRecordsTable from '@/modals/MeetRecordsTable.vue';
import MedicalRecord from '@/modals/MedicalRecord.vue';
import ScheduleCancelConfirm from '@/modals/ScheduleCancelConfirm.vue';

import TAtendimentEnum from '@/enums/TAtendimentEnum';

import AppointmentTypeEnum from '@/enums/AppointmentTypeEnum';

import moment from 'moment';

export default {
  components: {},
  mounted() {
    const id = this.$route.params.id;
    this.loadSchedule(id);
  },

  data: () => ({
    isLoading: true,
    schedule: {},
    viewPrescription: false,
    showTelephone: false,

    popoverClipboard: {
      title: 'Copiado!',
      variant: 'success',
    },
  }),

  computed: {
    ...mapGetters(['memedToken']),

    patientPhoto() {
      return this.schedule.patient.photo ?? require('@/assets/img/user.png');
    },

    showButtonConfirmed() {
      const now = moment().format('YYYY-MM-DD');
      const now_date = moment().format('YYYY-MM-DD HH:mm');

      const schedule_start = moment(this.schedule.start).format('YYYY-MM-DD');
      const schedule_end = moment(this.schedule.end).format('YYYY-MM-DD HH:mm');

      const is_schedule_today = schedule_start == now;
      const is_before = now_date <= schedule_end;

      return (
        !this.schedule.canceled_at &&
        !this.schedule.rescheduled_to &&
        this.schedule.tatendimento_id == TAtendimentEnum.ONLINE &&
        !this.schedule.confirmed &&
        is_schedule_today &&
        is_before
      );
    },

    showActionsButtons() {
      const now = moment().format('YYYY-MM-DD');
      const now_date = moment().format('YYYY-MM-DD HH:mm');

      const schedule_start = moment(this.schedule.start).format('YYYY-MM-DD');
      const schedule_end = moment(this.schedule.end).format('YYYY-MM-DD HH:mm');

      const minutes_diff = moment().diff(
        moment(this.schedule.start),
        'minutes'
      );
      const is_schedule_today = schedule_start == now;
      const is_before = now_date <= schedule_end;

      return (
        !this.schedule.canceled_at &&
        !this.schedule.rescheduled_to &&
        is_schedule_today &&
        ((this.schedule.tatendimento_id == TAtendimentEnum.SCHEDULED &&
          !this.schedule.performed &&
          minutes_diff >= -10 &&
          is_before) ||
          (this.schedule.tatendimento_id == TAtendimentEnum.ONLINE &&
            this.schedule.confirmed &&
            is_before))
      );
    },

    hiddenBtnCancel() {
      const minutes_diff = moment().diff(
        moment(this.schedule.start),
        'minutes'
      );

      return (
        this.schedule.canceled_at ||
        this.schedule.rescheduled_to ||
        (this.schedule.tatendimento_id == 2 &&
          (this.schedule.performed || minutes_diff >= -15)) ||
        (this.schedule.tatendimento_id == 1 &&
          (this.schedule.confirmed || minutes_diff >= 5))
      );
    },

    showButtonPhone() {
      return this.schedule.appointment_type_id == AppointmentTypeEnum.CALL;
    },
  },

  methods: {
    loadSchedule(id) {
      this.isLoading = true;

      SchedulesService.getId(id, {
        include: ['patient.user', 'patient.city.state'],
      })
        .then(({ data }) => (this.schedule = data))
        .finally(() => {
          this.isLoading = false;
        });
    },

    onConfirmClick() {
      SchedulesService.confirmeScheduleOnline(this.schedule.id)
        .then(() => {
          this.loadSchedule(this.schedule.id);
        })
        .catch(({ data }) => {
          this.$buefy.snackbar.open({
            message: data.errors,
            type: 'is-danger',
            duration: 5000,
            pauseOnHover: true,
          });
        });
    },

    onCancelClick() {
      if (this.schedule.tatendimento_id == 1) {
        return SchedulesService.cancelScheduleOnline(this.schedule.id)
          .then(({ data }) => {
            this.$buefy.snackbar.open({
              message: data.message,
              type: 'is-success',
              duration: 3000,
              pauseOnHover: true,
            });
            this.loadSchedule(this.schedule.id);
          })
          .catch(({ data }) => {
            this.notifyError(data, 'Erro inesperado ao cancelar atendimento!');
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$buefy.modal.open({
        parent: this,
        component: ScheduleCancelConfirm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          close: (data) => data && this.cancelSchedule(false),
        },
      });
      }

      
    },

    openRecord(record = null) {
      this.$buefy.modal.open({
        parent: this,
        component: MedicalRecord,
        hasModalCard: true,
        width: 3000,
        trapFocus: true,
        props: {
          record,
          scheduleId: this.schedule.id,
          patientId: this.schedule.patient_id,
        },
        events: {
          close: (data) => data && this.$refs.recordsTable.loadPatientRecords(),
        },
      });
    },

    onDeleteClick(recordId) {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente deletar esse prontuário?',
        onConfirm: () => this.deleteRecord(recordId),
      });
    },

    openPrescription(prescription = null) {
      if (prescription && prescription.by_memed) {
        window.MdHub.command.send(
          'plataforma.prescricao',
          'viewPrescription',
          prescription.memed_prescription_id
        );
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Prescription,
          width: 3000,
          hasModalCard: true,
          trapFocus: true,
          props: {
            prescription,
            schedule: this.schedule,
          },
          events: {
            close: (data) =>
              data && this.$refs.prescriptionsTable.loadPrescriptions(),
          },
        });
      }
    },

    openLink(flag, value = '') {
      const actions = {
        whatsapp: () => window.open(`https://wa.me/+55${value}`, '_blank'),

        phone: () => (this.showTelephone = true),
      };

      if (flag in actions) value ? actions[flag](value) : actions[flag]();
    },

    onScheduleMeetRecordsClick() {
      this.$buefy.modal.open({
        parent: this,
        component: MeetRecordsTable,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
        width: 600,
        props: {
          scheduleId: this.schedule.id,
        },
      });
    },

    onCopyPhone() {
      window.navigator.clipboard.writeText(this.schedule.patient.phone);
      setTimeout(
        () => this.$root.$emit('bv::hide::tooltip', 'phoneShow'),
        1200
      );
    },

    cancelSchedule(generate_discount = false) {
      this.isLoading = true;

      SchedulesService.cancelSchedule(this.schedule.id, {
        generate_discount,
      })
        .then(({ data }) => {
          this.$buefy.snackbar.open(
            data?.message ?? 'Consulta cancelada com sucesso!'
          );
          this.loadSchedule(this.schedule.id);
        })
        .catch(({ data }) => {
          let errorMessage = 'Erro inesperado ao cancelar atendimento!';

          this.notifyError(data, errorMessage);
        })
        .finally(() => (this.isLoading = false));
    },

    deleteRecord(id) {
      MedicalRecordModelService.deleteRecord(id).then(() => {
        this.$buefy.snackbar.open('Prontuário deletado com sucesso.');
        this.$refs.recordsTable.loadPatientRecords();
      });
    },

    reloadPrescriptionsTable() {
      this.$refs.prescriptionsTable.loadPrescriptions();
    },

    canViewPrescription(value) {
      this.viewPrescription = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-content {
    max-height: 50vh;
    overflow: auto;
  }

  .card-content.infos {
    display: flex;

    button {
      margin-right: 10px;
    }
  }
}
.patient {
  display: flex;

  &__image {
    margin-right: 24px;
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .actions {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
</style>
